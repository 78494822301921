export default () => {
  const SHOW_POS = 80;
  const FADE_COUNT = 300;
  const SCROLL_COUNT = 500;

  $ (function(){
    // 変数宣言と代入
    let pageTop = $('#pagetop');
    // ボタン非表示
    pageTop.hide();
    // 80pxスクロールしたらボタン表示
    $ (window).scroll(function () {
      if ($(this).scrollTop() > SHOW_POS) {
        // 0.3秒でフェードイン
        pageTop.fadeIn(FADE_COUNT);
      } else {
        // 0.3秒でフェードアウト
        pageTop.fadeOut(FADE_COUNT);
      }
    });
    // 0.5秒でページトップへ戻る
    pageTop.click(function () {
      $('body, html').animate({ scrollTop: 0}, SCROLL_COUNT);
      return false;
    })
  })

  $(function(){
    // #で始まるa要素をクリックした場合に処理（"#"←ダブルクォーテンションで囲むのを忘れずに。忘れるとjQueryのバージョンによっては動かない。。）
    $('a[href^="#"]').click(function(){
      // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
      var adjust = 0;
      // スクロールの速度（ミリ秒）
      var speed = 400;
      // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
      var href= $(this).attr("href");
      // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
      var target = $(href == "#" || href == "" ? 'html' : href);
      // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
      var position = target.offset().top + adjust;
      // スムーススクロール linear（等速） or swing（変速）
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
  });
}